<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          新增主模块
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <select_hospInfo
          :dense="true"
          outlined
          class="col-11"
          label="选择机构"
          v-model="HospID"
          v-model:valueCode="HospID"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
import * as PDM_CONFIG_KEY from "@/api/pdm/configKey";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    select_hospInfo,
  },
  props: {},
  computed: {
    ...mapState("PdmConfigKey", [
      "PdmConfigKeyShowDlg",
      "AddPdmConfigKeyShowDlg",
      "PdmConfigKeyEntity",
      "PdmConfigKeyList",
    ]),
  },
  data() {
    return {
      HospID: null,
    };
  },
  mounted() {},
  methods: {
    ...mapActions("PdmConfigKey", [
      "actUpdatePdmConfigKey",
      "actGetPdmConfigKeyList",
    ]),
    onOK() {
      if (!this.HospID) {
        this.msg.error("请选择机构！");
        return;
      }
      PDM_CONFIG_KEY.UpdatePDM_CONFIG_KEYlist({
        HospID: this.HospID,
      }).then((res) => {
        if (res.Code == 0) {
          this.$store.commit("PdmConfigKey/setAddPdmConfigKeyShowDlg", false);
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
