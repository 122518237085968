/*------------------------------------------------
// File Name:PDM_CONFIG_KEY.js
// File Description:PDM_CONFIG_KEY API方法js接口
// Author:zxl
// Create Time:2022/08/19 15:39:06
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _configKey: "/PDM_CONFIG_KEY"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_CONFIG_KEYEntity(parameter) {
    return axios({
        url: api._configKey + "/CreatePDM_CONFIG_KEYEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_CONFIG_KEYList(parameter) {
    return axios({
        url: api._configKey + "/GetPDM_CONFIG_KEYList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_CONFIG_KEYListByPage(parameter) {
    return axios({
        url: api._configKey + "/GetPDM_CONFIG_KEYListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_CONFIG_KEYEntity(parameter) {
    return axios({
        url: api._configKey + "/GetPDM_CONFIG_KEYEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_CONFIG_KEYEntity(parameter) {
    return axios({
        url: api._configKey+ "/UpdatePDM_CONFIG_KEYEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------保存对象集合---------------
export function UpdatePDM_CONFIG_KEYlist(parameter) {
    return axios({
        url: api._configKey+ "/UpdatePDM_CONFIG_KEYlist",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_CONFIG_KEYEntity(parameter) {
    return axios({
        url: api._configKey + "/DeletePDM_CONFIG_KEYEntity",
        method: 'post',
        data: parameter
    })
}
