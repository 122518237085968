<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          {{ PdmConfigKeyEntity.CONFIG_KEY_NAME }}——({{
            PdmConfigKeyEntity.HOSPNAME
          }})
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          label="模块序号"
          dense
          outlined
          class="col-2"
          v-model="PdmConfigKeyEntity.CONFIG_KEY_NO"
          disable
          readonly
        />
        <q-input
          label="机构名称"
          dense
          outlined
          class="col-5"
          v-model="PdmConfigKeyEntity.HOSPNAME"
          disable
          readonly
        />
        <q-input
          label="名称"
          dense
          outlined
          class="col-4"
          v-model="PdmConfigKeyEntity.CONFIG_KEY_NAME"
        />
      </div>
      <div class="q-gutter-sm q-mb-sm">
        <q-btn-toggle
          v-model="PdmConfigKeyEntity.CONFIG_KEY_IS_SWITCH"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '启用', value: 1 },
            { label: '不启用', value: 0 },
          ]"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  props: {},
  computed: {
    ...mapState("PdmConfigKey", [
      "PdmConfigKeyShowDlg",
      "PdmConfigKeyEntity",
      "PdmConfigKeyList",
    ]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmConfigKey", [
      "actUpdatePdmConfigKey",
      "actGetPdmConfigKeyList",
    ]),
    onOK() {
      this.actUpdatePdmConfigKey({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmConfigKeyEntity,
      }).then((res) => {
        if (res.Code == 0) {
          // setTimeout(()=> {
          //   this.$emit("onLoadData");
          // }, 200);
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
